<template>
  <div>
    <page-title :heading="$t('erp.lang_inventoryItem') "
                :permissionAdd="this.$store.getters['permissions/checkPermission']('ware_create') && !this.hybrid" show-add-button
                url-add="/erp/baseData/inventoryItems/add" :subheading="$t('erp.lang_inventoryItem')"
                :icon=icon></page-title>
    <div class="app-main__inner">
      <inventory-items></inventory-items>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../Layout/Components/PageTitle.vue";
import InventoryItems from "../../../components/erp/baseData/InventoryItems";

export default {
  components: {
    PageTitle,
    InventoryItems
  },

  data: () => ({
    icon: 'pe-7s-radio icon-gradient bg-tempting-azure',
  }),
  computed:{
    hybrid(){ return process.env.VUE_APP_HYBRID == 'true' || this.$store.getters["permissions/checkHybridApp"]},
  }
}
</script>