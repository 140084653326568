<template>
    <div class="mb-3 card">
        <div>


            <!-- Table -->
            <Datatable ref="intentoryItemsDatatable"
                       :api-endpoint="ENDPOINTS.DATATABLES.ERP.INVENTORYITEMS"
                       :datatable-headers="datatableHeaders"
                       :excel-columns="excelColumns"
                       excel-file-name="InventoryItems"
                       @editEntry="editEntry"
                       @deleteEntry="entryDelete"
                       @deleteEntries="entriesDelete"
                       show-delete-buttons
                       show-edit-buttons
                       :permissionDelete="this.$store.getters['permissions/checkPermission']('ware_delete')"
                       :permissionEdit="this.$store.getters['permissions/checkPermission']('ware_edit')"
            >
              <template v-slot:item.eknet="{item}">
                {{item.eknet|currency}}
              </template>
            </Datatable>

        </div>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </div>
</template>

<script>
    import {ENDPOINTS} from '../../../config'
    import {Events} from "../../../plugins/events";
    import Datatable from "../../datatable/Datatable";
    import mixin from "../../../mixins/KeyboardMixIns";

    export default {
        components: {
            Datatable
        },
        mixins: [mixin],
        data() {
            return {
                ENDPOINTS,
                id: null,
                tab: 0,
                // --- Datatable ---
                datatableHeaders: [
                    {
                        text: 'ID',
                        align: 'left',
                        value: 'id',
                        width: 80,
                        hide: true
                    },
                    {text: this.$t('erp.lang_ware_create_id'), value: "itemID"},
                    {text: this.$t('erp.lang_posItemName'), value: "itemName",sort: "asc"},
                    {text: this.$t('erp.lang_ware_create_desc'), value: "itemDescr"},
                    {text: this.$t('erp.lang_netPurchasePricePerUnit'), value: "eknet"}
                ],
                excelColumns: [

                    {
                        label: "ID",
                        field: "id",
                    },
                    {
                        label: this.$t('erp.lang_ware_create_id'),
                        field: "itemID",
                    },
                    {
                        label: this.$t('erp.lang_posItemName'),
                        field: "itemName",
                    },
                    {
                        label: this.$t('erp.lang_ware_create_desc'),
                        field: "itemDescr",
                    },
                    {
                        label: this.$t('erp.lang_netPurchasePricePerUnit'),
                        field: "eknet",
                    },
                ],
            }
        },

        methods: {
            editEntry(entry) {
                this.id = entry.id;
                this.$router.push({name: 'erp.baseData.inventoryItems.edit', params: {id: this.id}});
            },
            deleteData: function (idsToDelete = []) {
                let self = this;
                this.$swal({
                    title: this.$t('erp.lang_deleteInventoryItem'),
                    text: this.$t('erp.lang_deleteInventoryItemText'),
                    icon: "warning",
                    cancelButtonText: this.$t('generic.lang_cancel'),
                    confirmButtonText: this.$t('generic.lang_delete'),
                    showCancelButton: true,
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                        if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
                            idsToDelete = [];
                            idsToDelete.push(parseInt(this.id));
                        }
                        this.axios.post(ENDPOINTS.ERP.INVENTORYITEM.DELETE, {
                            deleteItemID: idsToDelete
                        }).then((res) => {
                            if (res.data.status === 'SUCCESS') {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('erp.lang_inventoryItemsDeleted'),
                                    color: "success"
                                });

                                self.resetData();
                                self.$refs.intentoryItemsDatatable.getDataFromApi();
                                self.$refs.intentoryItemsDatatable.resetSelectedRows();
                            } else {
                                Events.$emit("showSnackbar", {
                                    message: this.$t('generic.lang_errorOccurred'),
                                    color: "error"
                                });
                            }
                        });
                    },
                    allowOutsideClick: () => !this.$swal.isLoading()
                });
            },
            resetData: function () {
                this.id = null;
            },
            showList: function () {
                this.resetData();
            },
            entryDelete(entry) {
                this.id = entry.id;

                this.deleteData();
            },
            entriesDelete(entries) {
                this.deleteData(entries);
            }
        },
    }
</script>